<template>
    <div class="question__date" v-if="positionY && isNearestPlace" v-bind:style="{
        'top': positionY + 'px',
        'height': height + 'px',
        'border-color': tableQuestionPlace.color,
    }">
    </div>
</template>

<script>
import { cardPosition } from '../../../scripts/cardPosition';

export default {
    name: 'QuestionDate',
    props: {
        workStart: {
            type: Object,
            default: () => {
                return {
                    "hour": 0,
                    "minute": 0,
                }
            }
        },
        ceilHeight: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        tableQuestionPlace() {
            return this.$store.getters.tableQuestionPlace
        },
        isNearestPlace(){
            return this.tableQuestionPlace.isNearestPlace
        },
        positionY(){
            if (!this.isNearestPlace) return

            let date = this.tableQuestionPlace.date
            return cardPosition(date, this.workStart, this.ceilHeight)
        },
        height(){
            if (!this.isNearestPlace) return

            return this.tableQuestionPlace.duration / 60 * this.ceilHeight
        }
    }
};
</script>

<style scoped>
.question__date{
    width: 100%;
    font-size: 12px;
    position: absolute;

    border: 3px dashed lightgrey;
    color: lightgrey;
    z-index: 2;
}
</style>